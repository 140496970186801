<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Cliente')}}</h3>
		</div>		
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="origen" :header="$t('Oficina')" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.origen}}
						</template>
					</Column>					
					<Column field="cliente" :header="$t('Cliente')+'#'" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.cliente}}
						</template>
					</Column>
					<Column field="nombre" :header="$t('Nombre Completo')" :sortable="true" headerStyle="width: 180px">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="antiguedad" :header="$t('Antigüedad')" headerStyle="width: 60px">
						<template #body="slotProps">
							{{slotProps.data.antiguedad}}
						</template>
					</Column>
					<Column field="claim"  :header="$t('Estatus')" headerStyle="width: 60px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">{{$t('Activo')}}</span>
							<span class="p-tag p-tag-danger" v-else>{{$t('No Activo')}}</span>
							{{slotProps.data.claim}}
						</template>
					</Column>
					<Column :header="$t('Acciones')" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit"/>
							<span class="p-overlay-badge p-mr-4">
								<Button :disabled ="$store.state.loading" icon="pi pi-eye" class="p-button-rounded p-button-info" @click="Ver1(slotProps.data)"/>
							</span>
							<span class="p-overlay-badge p-mr-4">
								<Button :disabled ="$store.state.loading" icon="pi pi-eye" class="p-button-rounded p-button-danger" @click="Ver2(slotProps.data)"/>
							</span>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Cliente')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-2">
								<label for="name">{{$t('Cliente')+'#'}}</label>
								<InputText id="cedula" v-model="product.cliente" placeholder="Disabled" :disabled="true"></InputText>						
							</div>
							<div class="p-col-3">
								<label for="name">{{$t('Número ID')}}</label>
								<InputText v-model="product.code" type="text" required="true"/>				
							</div>
							<div class="p-col-7">
								<label for="name">{{$t('Nombre Completo')}}</label>
								<InputText id="name" v-model.trim="product.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-5">
								<label class="p-mb-3">{{$t('Oficina')}}</label>
								<Dropdown id="state" v-model="product.origen" :options="origenes" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-7">
								<label for="name">{{$t('Correo')}}</label>
								<InputText id="name" v-model.trim="product.correo" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-3">
								<label for="name">{{$t('Teléfono móvil')}}</label>
								<InputMask v-model="product.celular" mask="+99999 999999"/>
							</div>
							<div class="p-col-3">
								<label for="name">{{$t('Teléfono privado')}}</label>
								<InputMask v-model="product.tlflocal" mask="+99999 999999"/>
							</div>        
							<div class="p-col-3">
								<label for="name">{{$t('Fecha de Inicio')}}</label>
								<InputText v-model="product.fecha" type="date"/>				
							</div>
							<div class="p-col-3">
								<label for="name">{{$t('Antigüedad')}}+</label>
								<InputText id="name" v-model.trim="product.antiquity" type="number" min="0"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">{{$t('Estatus')}}</h6>
								<InputSwitch v-model="product.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="productDialog2" :style="{width: size}" :header="$t('Detalles')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12" v-if="det_polizas.length>0">
								<h4>{{$t('Polizas')}}</h4>
								<DataTable :value="det_polizas" responsiveLayout="scroll">
									<Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 100px">
										<template #body="slotProps">
											<div v-if="slotProps.data.estado==4">
												{{$t('Verificación  de Pago')}}
											</div>
											<div v-else-if="slotProps.data.vend<=0">
												{{$t('Vencida')}}
												<div v-if="slotProps.data.estado==7">{{$t('Proceso Renovación')}}</div>
											</div>
											<div v-else-if="slotProps.data.estado==6">
												{{$t('Anulada')}}
											</div>
											<div v-else-if="slotProps.data.pendiente>0">
												{{$t('Deuda Vencida')}} <br> ({{$t('Suspendida')}})
											</div>
											<div v-else-if="slotProps.data.proceso>0">
												{{$t('Pago Vencido')}} <br> ({{$t('Suspendida')}})
											</div>
											<div v-else-if="slotProps.data.estado==7">
												{{$t('Proceso Renovación')}}
											</div>
											<div v-else>
												{{$t('Activa')}}
											</div>
										</template>
									</Column>       
									<Column :header="$t('Póliza')+' #'" field="codigo" :sortable="true" headerStyle="width: 250px">
										<template #body="slotProps">
											<div>
												{{slotProps.data.codigo}}
											</div>     
										</template>
									</Column>
									<Column field="duracion_name" :header="$t('Duración')" :sortable="true">
										<template #body="slotProps">
											<div v-if="i18n.locale() == 'es'">{{slotProps.data.duracion_nombre}}</div>
											<div v-else>{{slotProps.data.duracion_name}}</div>     
										</template>
									</Column>
									<Column field="vence" :header="$t('Vencimiento')" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.vence}}
										</template>
									</Column>
								</DataTable>
							</div>
							<div class="p-col-12" v-if="det_cotizaciones.length>0">
								<h4>{{$t('Cotizaciones')}}</h4>
								<DataTable :value="det_cotizaciones" responsiveLayout="scroll">
								<Column :header="$t('Estatus')">
									<template #body="slotProps">
										<strong v-if="slotProps.data.estado==0">{{$t('Anulada')}}</strong>
										<strong v-if="slotProps.data.estado==1">{{$t('Inicial')}}</strong>
										<strong v-if="slotProps.data.estado==2">{{ $t('Evaluación')}}</strong>
										<strong v-if="slotProps.data.estado==3">{{ $t('Compromiso')}}</strong>
									</template>
								</Column>
									<Column field="fecha" :header="$t('Fecha')">
										<template #body="slotProps">
											{{slotProps.data.fecha}}                              
										</template>
									</Column>
									<Column field="id" :header="$t('Nº')">
										<template #body="slotProps">
											{{slotProps.data.id}}                              
										</template>
									</Column>
									<Column field="duracion_name" :header="$t('Tipo')" :sortable="true">
										<template #body="slotProps">
											<div v-if="i18n.locale() == 'es'">{{slotProps.data.poliza_nombre}}</div>
											<div v-else>{{slotProps.data.poliza_name}}</div>     
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger" @click="productDialog2=false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="productDialog3" :style="{width: size}" :header="$t('Detalles')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12"  v-if="det_claims1.length>0">
								<h4>{{$t('Reclamaciones: Incendio')}}</h4>
								<DataTable :value="det_claims1" responsiveLayout="scroll">
									<Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 100px">
										<template #body="slotProps">
											{{convfech(slotProps.data.fecha)}}
										</template>
									</Column>
									<Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
										<template #body="slotProps">
											{{slotProps.data.codigo}}                       
										</template>
									</Column>
									<Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
										<template #body="slotProps">
											{{slotProps.data.poliz}}                        
										</template>
									</Column>
									<Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 100px">
										<template #body="slotProps">
											<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
												{{slotProps.data.estado_nombre}}
											</strong>
											<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
												{{slotProps.data.estado_name}}
											</strong>
										</template>
									</Column>   
								</DataTable>
							</div>
							<div class="p-col-12" v-if="det_claims2.length>0">
								<h4>{{$t('Reclamaciones: Vehículo')}}</h4>
								<DataTable :value="det_claims2" responsiveLayout="scroll">
									<Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true">
										<template #body="slotProps">
											{{convfech(slotProps.data.fecha)}}
										</template>
									</Column>
									<Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
										<template #body="slotProps">
											{{slotProps.data.codigo}}                       
										</template>
									</Column>
									<Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
										<template #body="slotProps">
											{{slotProps.data.poliz}}                        
										</template>
									</Column>
									<Column :header="$t('Estatus')" field="estado_name" :sortable="true">
										<template #body="slotProps">
											<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
												{{slotProps.data.estado_nombre}}
											</strong>
											<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
												{{slotProps.data.estado_name}}
											</strong>
										</template>
									</Column>   
								</DataTable>
							</div>
							<div class="p-col-12" v-if="det_claims3.length>0">
								<h4>{{$t('Reclamaciones: Salud')}}</h4>
								<DataTable :value="det_claims3" responsiveLayout="scroll">
									<Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true">
										<template #body="slotProps">
											{{convfech(slotProps.data.fecha)}}
										</template>
									</Column>
									<Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
										<template #body="slotProps">
											{{slotProps.data.codigo}}                       
										</template>
									</Column>
									<Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
										<template #body="slotProps">
											{{slotProps.data.poliz}}                        
										</template>
									</Column>
									<Column :header="$t('Estatus')" field="estado_name" :sortable="true">
										<template #body="slotProps">
											<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
												{{slotProps.data.estado_nombre}}
											</strong>
											<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
												{{slotProps.data.estado_name}}
											</strong>
										</template>
									</Column>   
								</DataTable>
							</div>
							<div class="p-col-12" v-if="det_claims4.length>0">
								<h4>{{$t('Reclamaciones: Proyecto')}}</h4>
								<DataTable :value="det_claims4" responsiveLayout="scroll">
									<Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true">
										<template #body="slotProps">
											{{convfech(slotProps.data.fecha)}}
										</template>
									</Column>
									<Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
										<template #body="slotProps">
											{{slotProps.data.codigo}}                       
										</template>
									</Column>
									<Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
										<template #body="slotProps">
											{{slotProps.data.poliz}}                        
										</template>
									</Column>
									<Column :header="$t('Estatus')" field="estado_name" :sortable="true">
										<template #body="slotProps">
											<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
												{{slotProps.data.estado_nombre}}
											</strong>
											<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
												{{slotProps.data.estado_name}}
											</strong>
										</template>
									</Column>   
								</DataTable>
							</div>
						</div>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger" @click="productDialog3=false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Clientes');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				origenes: [],
				productDialog: false,
				productDialog2: false,
				productDialog3: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '60vw',
				detalles: {},
				det_polizas: [],
				det_cotizaciones: [],
				det_claims1: [],
				det_claims2: [],
				det_claims3: [],
				det_claims4: [],
				opcion: {code: 1,   nombre: 'Activo', name: 'Active'},
				opciones: [
					{code: 1,   nombre: 'Activo', name: 'Active'},
					{code: 2,  nombre: 'No Activo', name: 'Inactive'},
				],
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
			this.Buscar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.origenes = result.consult.origenes;
				}); 
			},
			Buscar(){
				this.products = [];
				Consulta.Procesar('Buscar',{
					opcion: this.opcion.code,
					}).then(response => {
						if (response.result.usuarios){
							this.products = response.result.usuarios;
						}
					});
			},		
			openNew() {
				this.nuevo = true;
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			Ver1(data) {
				this.detalles = {},
				this.detalles = data;
				this.det_polizas = [];
				this.det_cotizaciones = [];
				Consulta.Procesar('Polizas',{
					cliente: data,
				}).then(response => {
					this.det_polizas = response.result.poliza;
					this.det_cotizaciones = response.result.cotizacion;
					this.productDialog2 = true;
				});
			},
			Ver2(data) {
				this.detalles = {},
				this.detalles = data;
				this.det_claims1 = [];
				this.det_claims2 = [];
				this.det_claims3 = [];
				this.det_claims4 = [];
				Consulta.Procesar('Claims',{
					cliente: data,
				}).then(response => {
					this.det_claims1 = response.result.claim1;
					this.det_claims2 = response.result.claim2;
					this.det_claims3 = response.result.claim3;
					this.det_claims4 = response.result.claim4;
					this.productDialog3 = true;
				});
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.correo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Correo', life: 5000});
				} else if (this.product.origen == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Oficina', life: 5000});
				} else {			
					this.productDialog = false;
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							code: 	this.product.code,
							nombre: this.product.nombre,
							correo: this.product.correo,
							celular: this.product.celular,
							tlflocal: this.product.tlflocal,
							origen: this.product.origen.code,
							fecha: this.product.fecha,
							antiquity: this.product.antiquity,
							activo: this.product.activo
						}).then(response => {
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.nuevo = false;
				this.product = {...product};
				this.origenes.forEach(element => {
					if(this.product.origen_id==element.code){
						this.product.origen = element;
					}
				});  
				this.productDialog = true;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
